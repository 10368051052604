import React from 'react';
import ComponentWrapper from '../../layout/ComponentWrapper';
import { StudyDesignOverview, StudyDesignArms, StudyCopyBlock } from '../index';

const NSCLC_NEOADJUVANT = () => (
    
    <ComponentWrapper>
        <StudyDesignOverview title="Study Overview" description="KEYNOTE&#8288;-&#8288;671: A randomized, double-blind, multicenter, placebo-controlled, phase 3 study in patients with resectable stage II, IIIA, or IIIB (N2) NSCLC per AJCC 8th edition<sup>2</sup>" data={[
             [
                {
                    title: 'Key Inclusion Criteria',
                    description: `
                        <ul>
                            <li>Patients with previously untreated and resectable stage II, IIIA, or IIIB (N2) NSCLC per AJCC 8th ed </li>
                            <li>Regardless of tumor PD&#8288;-&#8288;L1 expression</li>
                        </ul>
                        <h5>Key Exclusion Criteria</h5>
                        <ul>
                            <li>Active autoimmune disease that required systemic therapy within 2 years of treatment</li>
                            <li>A medical condition that required immunosuppression</li>
                            <li>A history of interstitial lung disease or pneumonitis that required steroids</li>
                        </ul>
                    `,
                    type: 'box-gradient',
                    colspan: 2
                },
            ],
            [
                {
                    type: 'arrow-gray',
                    colspan: 2
                }
            ],
            [
                {
                    title: 'Randomized<sup>m</sup> 1:1 (N=797)',
                    colspan: 2,
                }
            ],
            [
                {
                    type: 'arrow-green'
                },
                {
                    type: 'arrow-gray'
                }
            ],
        ]} />
    
        <StudyDesignArms title="Study Arms" arms={[
            {
                data: [
                    [
                        {
                            title: '<b>Neoadjuvant therapy</b><br/>Every 3 weeks (up to 4 cycles)<br/>(n=397)',
                            description: [
                                {
                                    text: `KEYTRUDA 200 mg IV`,
                                    textCenter: true
                                },
                                {
                                    text: `cisplatin and pemetrexed<sup>n</sup>`,
                                    conditional: 'and',
                                    textCenter: true
                                },
                                {
                                    text: `cisplatin and gemcitabine<sup>o</sup>`,
                                    conditional: 'or',
                                    textCenter: true
                                },
                            ],
                            type: 'box-multi-color-green'
                        }
                    ],
                    [
                        {
                            type: 'arrow-green'
                        }
                    ],
                    [
                        {
                             title: '<strong>SURGERY</strong>',
                        }
                    ],
                    [
                        {
                            type: 'arrow-green'
                        }
                    ],
                    [
                        {
                            title: '<b>Adjuvant therapy</b><br/>(Initiated within 4—12 weeks)<br/> Every 3 weeks (up to 13 cycles)',
                            description: [
                                {
                                    text: `KEYTRUDA 200 mg IV`,
                                    textCenter: true
                                },
                            ],
                            type: 'box-multi-color-green'
                        }
                    ]
                ]
            },
            {
                data: [
                    [
                        {
                            title: '<b>Neoadjuvant therapy</b><br/>Every 3 weeks (up to 4 cycles)<br/>(n=400) ',
                            description: [
                                {
                                    text: `Placebo IV`,
                                    textCenter: true
                                },
                                {
                                    text: `cisplatin and pemetrexed<sup>n</sup>`,
                                    conditional: 'and',
                                    textCenter: true
                                },
                                {
                                    text: `cisplatin and gemcitabine<sup>o</sup>`,
                                    conditional: 'or',
                                    textCenter: true
                                },
                            ],
                            type: 'box-multi-color-gray'
                        }
                    ],
                    [
                        {
                            type: 'arrow-gray'
                        }
                    ],
                    [
                        {
                            title: '<strong>SURGERY</strong>',
                        }
                    ],
                    [
                        {
                            type: 'arrow-gray'
                        }
                    ],
                    [
                        {
                            title: '<b>Adjuvant therapy</b><br/>(Initiated within 4—12 weeks)<br/>Every 3 weeks (up to 13 cycles)',
                            description: [
                                {
                                    text: `Placebo IV`,
                                    textCenter: true
                                },
                            ],
                            type: 'box-multi-color-gray'
                        }
                    ],
                ]
            }
        ]} />

        <StudyCopyBlock 
            description={`
                <ul>
                    <li>Treatment continued until completion (17 cycles), disease progression that precluded definitive surgery, disease recurrence in the adjuvant phase, disease progression for those who did not undergo surgery or had incomplete resection and entered the adjuvant phase, or unacceptable toxicity.</li>
                    <li>The trial was not designed to isolate the effect of KEYTRUDA in each phase (neoadjuvant or adjuvant) of treatment.</li>
                </ul>
            `} 
        />
   
        <StudyCopyBlock 
            title="Endpoints"
            description={`
                <ul>
                    <li><strong>Major efficacy outcome measures:</strong> OS and investigator-assessed EFS</li>
                    <li><strong>Additional efficacy outcome measures:</strong> pCR<sup>p</sup> rate and mPR<sup>p</sup> rate</li>
                </ul>
                <p>Overall survival (OS) was defined as the time from randomization to death from any cause.<sup>2</sup></p>
                <p>Event-free survival (EFS) was defined as the time from randomization to the first occurrence of local progression that precluded the planned surgery, unresectable tumor, progression or recurrence according to RECIST v1.1, by the investigator’s assessment, or death from any cause.<sup>2</sup></p>
                <p>Pathological complete response (pCR) rate was defined as the absence of residual invasive cancer in resected primary tumor and lymph nodes.<sup>2,p</sup></p>
                <p>Major pathological response (mPR) rate was defined as ≤10% viable tumor cells in resected primary tumor and lymph nodes.<sup>2,p</sup></p>
            `} 
        />

        <StudyCopyBlock 
            title="Patient Baseline Characteristics"
            description={`
                <ul>           
                    <li>Median age: 64 years (range: 26–83)</li>
                    <li>Age 65 or older: 45%</li>
                    <li>Age 75 years or older: 7%</li>
                    <li>Male: 71%</li>
                    <li>Ethnicity/race
                        <ul>
                            <li>White: 61%</li>
                            <li>Asian: 31%</li>
                            <li>Hispanic or Latino: 9%</li>
                            <li>Race not reported: 4%</li>
                            <li>Black: 2%</li>
                        </ul>
                    </li>
                    <li>Geographic region:
                        <ul>
                            <li>East Asia: 31%</li>
                        </ul>
                    </li>              
                    <li>ECOG PS
                        <ul>
                            <li>0: 63%</li>
                            <li>1: 37%</li>
                        </ul>
                    </li>              
                    <li>Stage of disease at baseline<sup>2,q</sup>
                        <ul>
                            <li>II: 30%</li>
                            <li>III: 70%</li>
                        </ul>
                    </li>              
                    <li>PD&#8288;-&#8288;L1 expression
                        <ul>
                            <li>TPS <50%: 67%</li>
                            <li>TPS ≥50%: 33%</li>
                        </ul>
                    </li>              
                    <li>Histology
                        <ul>
                            <li>Nonsquamous: 57%</li>
                            <li>Squamous: 43%</li>
                        </ul>
                    </li>                          
                </ul>
                <p>81% of patients in the KEYTRUDA in combination with platinum-containing chemotherapy arm received definitive surgery compared to 76% of patients in the placebo in combination with platinum-containing chemotherapy arm.</p>
            `} 
        />
    
        <StudyCopyBlock 
            footnotes={[
                {
                    label:'m.',
                    text: 'Randomization was stratified by stage (II vs III), tumor PD&#8288;-&#8288;L1 expression (TPS&nbsp;≥50% vs <50%), histology (squamous vs nonsquamous), and geographic region (East Asia vs non-East Asia).'
                },
                {
                    label:'n.',
                    text: 'Cisplatin 75&nbsp;mg/m<sup>2</sup> IV on day 1 + pemetrexed 500&nbsp;mg/m<sup>2</sup> IV on day 1 of each 21-day cycle.'
                },
                ,{
                    label:'o.',
                    text: 'Cisplatin 75&nbsp;mg/m<sup>2</sup> IV on day 1 + gemcitabine 1,000&nbsp;mg/m<sup>2</sup> IV on days 1 and 8 of each 21-day cycle.'
                },
                {
                    label:'p.',
                    text: 'Assessed by blinded independent pathology review.'
                },
                {
                    label:'q.',
                    text: 'Per AJCC 8th ed.'
                },
            ]}
            definitions={`
            AJCC = American Joint Committee on Cancer; ECOG PS = Eastern Cooperative Oncology Group performance status; RECIST v1.1 = Response Evaluation Criteria In Solid Tumors v1.1; TPS = tumor proportion score.
            `} 
        />
    </ComponentWrapper>
);

export default NSCLC_NEOADJUVANT;
