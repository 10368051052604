import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../layouts/TemplateColumn';
import { 
    PageHeader, 
    PageSection, 
    SecondaryCallout,
    RelatedContent, 
    CrossLink, 
    IndicationPageIntro, 
    AnchorLinks,
    PrimaryClaim,
    KMCurve,
    FeaturedStat,
    ComparisonTable,
    PageSubSection,
    PatientProfile,
    ReferencesBlock,
    TextBlock,
    NCCNcallout
} from '../../components';
import { StaticImage } from 'gatsby-plugin-image';
import { jobCodes } from '../../util/data/jobCodes';
import NSCLC_NEOADJUVANT from '../../components/StudyDesign/__study-design/nsclc-perioperative-regimen';
import { Heading } from '../../util/typography';
import styled from 'styled-components';
import { createMarkup } from '../../util/functions';

// SEO INFORMATION 
const pageMeta = {
    title: `KEYNOTE-671 - Clinical Trial Results | HCP`,
    keywords: `keynote-671`,
    description: `Health care professionals may find KEYNOTE-671 clinical trial results for neoadjuvant combination therapy followed by adjuvant monotherapy after surgery in patients with resectable NSCLC.`,
    schemaJsonLD: [
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/nsclc-perioperative-regimen/","@type":"ApprovedIndication","name":" KEYTRUDA®","alternateName":"pembrolizumab","description":"KEYTRUDA is indicated for the treatment of patients with resectable (tumors ≥4 cm or node positive) non–small cell lung cancer (NSCLC) in combination with platinum⁠-⁠containing chemotherapy as neoadjuvant treatment, and then continued as a single agent as adjuvant treatment after surgery. "}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/nsclc-perioperative-regimen/","@type":"MedicalAudience","audienceType":"US Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico. "}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/nsclc-perioperative-regimen/","@type":"MedicalTrial","name":"Clinical Findings From KEYNOTE⁠-⁠671","description":"Study Design for KEYNOTE⁠-⁠671","trialDesign":"KEYNOTE⁠-⁠671: A randomized, double-blind, multicenter, placebo-controlled, phase 3 study in patients with resectable stage II, IIIA, or IIIB (N2) NSCLC per AJCC 8th edition"}`
    ]
}

const htmlPRR = `The trial demonstrated a statistically significant difference in pathological complete response (pCR) rate (18.1% vs 4.0%; <i>P</i>&lt;0.0001) and major pathological response (mPR) rate (30.2% vs 11.0%; <i>P</i>&lt;0.0001).`;

const StudyTitle = styled(Heading.H4)`
    margin-bottom: 10px;
`;

//from /src/util/data/indications.js
const indicationId = 34; 
const jobCode = jobCodes[4].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections : [
        {
            heading: "Additional Clinical Data",
            items: [
                { text: '1L Combo Therapy – Nonsquamous ', url: '/efficacy/nsclc-nonsquamous-first-line-combination-therapy' },
                { text: '1L Combo Therapy – Squamous ', url: '/efficacy/nsclc-squamous-first-line-combination-therapy' },
                { text: '1L Monotherapy – Nonsquamous & Squamous', url: '/efficacy/nsclc-first-line-monotherapy' },
                { text: '2L Monotherapy – Nonsquamous & Squamous', url: '/efficacy/nsclc-second-line-monotherapy' },
                { text: 'Adjuvant Therapy for Stage IB (T2a ≥4 cm), Stage II, or Stage IIIA NSCLC', url: '/efficacy/nsclc-adjuvant-therapy' },
            ]
        },
        {
            heading: "Related Information",
            items: [
                { text: 'Selected Adverse Reactions', url: '/safety/adverse-reactions/nsclc-perioperative-regimen' },
                { text: 'Dosing', url: '/dosing/options/' },
                { text: 'PD&#8288;-&#8288;L1 Testing & Scoring', url: '/biomarker-testing/pd-l1/' },
            ]
        },
    ],
    interestLinks : {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const featuredStatFootnotesOne = [
    {
      label: 'a.',
      text: 'Chemotherapy consisted of cisplatin 75 mg/m<sup>2</sup> IV on day 1 + (pemetrexed 500 mg/m<sup>2</sup> IV on day 1 or gemcitabine 1,000 mg/m<sup>2</sup> IV on days 1 and 8) of each 21-day cycle for up to 4 cycles.'
    }
  ]

const featuredStatFootnotesTwo = [
  {
    label: 'b.',
    text: 'HR based on Cox regression model with treatment as a covariate stratified by stage, tumor PD&#8288;-&#8288;L1 expression, histology, and geographic region.'
  },
  {
    label: 'c.',
    text: '<i>P</i> value based on stratified log-rank test.'
  },
  {
    label: 'd.',
    text: 'Compared to a two-sided <i>P</i> value boundary of 0.0109.'
  }
]

const featuredStatFootnotesThree = [
    {
        label: 'e.',
        text: 'HR based on Cox regression model with treatment as a covariate stratified by stage, tumor PD-L1 expression, histology, and geographic region.'
    },
    {
        label: 'f.',
        text: '<i>P</i> value based on stratified log-rank test.'
    },
    {
        label: 'g.',
        text: 'Compared to a two-sided <i>P</i> value boundary of 0.0109.'
    },
    {
      label: 'h.',
      text: 'Median OS based on Kaplan-Meier estimates.'
    }
  ]

const pathologicalRrFootnotes = [
    {
        label: 'i.',
        text: 'HR based on Cox regression model with treatment as a covariate stratified by stage, tumor PD&#8288;-&#8288;L1 expression, histology, and geographic region.'
    },
    {
        label: 'j.',
        text: '<i>P</i> value based on stratified log-rank test.'
    },
    {
        label: 'k.',
        text: 'Compared to a two-sided <i>P</i> value boundary of 0.0092.'
    },
    {
        label: 'l.',
        text: 'Median EFS based on Kaplan-Meier estimates.'
    }
]

const patientProfileJess = {
    image: () => (
        <StaticImage layout="fixed" src="../../assets/jess.png" placeholder="TRACED_SVG" alt="..." width={100} height={100} />
    ),
    name: "Jess",
    diagnosis: "Stage IIB<sup>r</sup> and T2bN1M0 nonsquamous NSCLC",
    primaryCharacteristics: [
        {
            key: 'Treatment History',
            value: 'No prior history of therapy',
            emphasize: false,
        },
        {
            key: 'Imaging Exam',
            value: '4.8-cm mass with ipsilateral hilar lymph node involvement',
            emphasize: false,
        },
        {
            key: 'ECOG PS',
            value: '0',
            emphasize: false,
        },
        {
            key: 'PD&#8288;-&#8288;L1 Expression ',
            value: 'TPS 65%',
            emphasize: false,
        },     
    ],
    secondaryCharacteristics: [
        {
            key: 'Age',
            value: '58',
        },
        {
            key: 'Gender',
            value: 'Female',
        },
        {
            key: 'Physical Activity',
            value: 'Moderate',
        },
    ],
}


const patientProfilePablo = {
    image: () => (
        <StaticImage layout="fixed" src="../../assets/pablo.png" placeholder="TRACED_SVG" alt="..." width={100} height={100} />
    ),
    name: "Pablo",
    diagnosis: "Stage IIIA<sup>r</sup> and T1cN2M0 nonsquamous NSCLC",
    primaryCharacteristics: [
        {
            key: 'Treatment History',
            value: 'No prior history of therapy',
            emphasize: false,
        },
        {
            key: 'Imaging Exam',
            value: '2.9-cm mass with ipsilateral hilar and paratracheal lymph node involvement',
            emphasize: false,
        },
        {
            key: 'ECOG PS',
            value: '1',
            emphasize: false,
        },
        {
            key: 'PD&#8288;-&#8288;L1 Expression ',
            value: 'TPS 0%',
            emphasize: false,
        },     
    ],
    secondaryCharacteristics: [
        {
            key: 'Age',
            value: '73',
        },
        {
            key: 'Gender',
            value: 'Male',
        },
        {
            key: 'Physical Activity',
            value: 'Low',
        },
    ],
    footnotes: [
        {
            label: 'r.',
            text: 'Per AJCC 8th ed.'
        }
    ]
}

const pageReferences = [
    {
        label: "1.",
        text: "Spicer JD, Garassino MC, Wakelee H, et al. Neoadjuvant pembrolizumab plus chemotherapy followed by adjuvant pembrolizumab compared with neoadjuvant chemotherapy alone in patients with early-stage non-small-cell lung cancer (KEYNOTE-671): a randomised, double-blind, placebo-controlled, phase 3 trial. <i>Lancet</i>. 2024;404(10459):1240-1252. doi:10.1016/S0140-6736(24)01756-2"
    },
    {
        label: "2.",
        text: "Wakelee H, Liberman M, Kato T, et al. Perioperative pembrolizumab for early-stage non-small-cell lung cancer. <i>N Engl J Med.</i> Published online June 3, 2023. doi:10.1056/NEJMoa2302983"
    },
]

const NCCNcalloutFootnotes = [
    {
        label: "r.",
        text: "Contraindications for treatment with PD-1/PD-L1 inhibitors may include active or previously documented autoimmune disease and/or current use of immunosuppressive agents; some oncogenic drivers (ie, <i>EGFR</i> exon 19 deletion or exon 21 L858R, <i>ALK</i> rearrangements) have been shown to be associated with less benefit from PD-1/PD-L1 inhibitors.<sup>3</sup>"
    },
]
const NCCNcalloutData = {   
    title: 'Pembrolizumab (KEYTRUDA): NCCN Category 1 Recommendation From the National Comprehensive Cancer Network® (NCCN®) as Part of a Neoadjuvant Regimen and Then Continued as Adjuvant Therapy After Surgery for Certain Patients with Resectable (T ≥4 cm or N+) NSCLC, Regardless of PD-L1 Expression<sup>3,r</sup>',
    bodyCopy: '<p><b>NCCN Category 1 Recommendation:</b></p> <p>The NCCN Guidelines<strong>®</strong> recommend pembrolizumab 200 mg and cisplatin-based doublet chemotherapy every 3 weeks for 4 cycles and then continued as single-agent pembrolizumab as adjuvant treatment after surgery (category 1)<sup>3,r</sup></p><ul><li>Cisplatin 75&#8288; &#8288;mg/m<sup>2</sup> day 1, gemcitabine 1,000&#8288; &#8288;mg/m<sup>2</sup> days 1 and 8 (squamous histology)</li><li>Cisplatin 75&#8288; &#8288;mg/m<sup>2</sup> day 1, pemetrexed 500&#8288; &#8288;mg/m<sup>2</sup> day 1 (nonsquamous histology)</li></ul><p>Pembrolizumab 200 mg every 3 weeks or 400 mg every 6 weeks for up to 39 weeks for patients with completely resected stage II-IIIA or stage IIIB (T3, N2) NSCLC who received previous neoadjuvant pembrolizumab + chemotherapy (category 1)</p>',
    showBadge: false,
    alt: ""
}

const NCCNcalloutDefinitions = `<p>Category 1 = Based upon high-level evidence (≥1 randomized phase 3 trials or high-quality, robust meta-analyses), there is uniform NCCN consensus (≥85% support of the Panel) that the intervention is appropriate.<sup>3</sup></p>
                                <p>NCCN makes no warranties of any kind whatsoever regarding their content, use, or application and disclaims any responsibility for their application or use in any way.</p>
                                <p><i>ALK</i> = anaplastic lymphoma kinase; <i>EGFR</i> = epidermal growth factor receptor; PD&#8288;-&#8288;1 = programmed death receptor&#8288;-&#8288;1;</p>`

const NCCNcalloutReferences = [
    {
    label: "3.",
    text: "Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Non-Small Cell Lung Cancer V.11.2024. © National Comprehensive Cancer Network, Inc. 2024. All rights reserved. Accessed October 15, 2024. To view the most recent and complete version of the guidelines, go online to NCCN.org."    },
]

const anchorLinkModelData = [
    {label: 'Clinical Findings From KEYNOTE&#8288;-&#8288;671'},
    {label: 'Study Design for KEYNOTE&#8288;-&#8288;671'},
    {label: 'Appropriate Patients'},
];

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={ pageMeta } location={ location }>
            <div data-template-name="template-e">

                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Clinical Trial Results" />
                   <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId}/>
                        <AnchorLinks items={anchorLinkModelData} />
                   </PageSection>
                   <PageSection bgColor="gradient">
                        <PrimaryClaim theme="light">
                            <b>The Only IO, as Part of a Neoadjuvant Followed by Adjuvant Regimen, to Demonstrate a Statistically Significant Improvement in Overall Survival in Resectable</b> (T ≥4 cm or N+) <b>NSCLC</b> vs Placebo + Chemo/Placebo
                        </PrimaryClaim>
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">
                        <PageSection title={anchorLinkModelData[0].label} bgColor="cloud">
                        <PageSubSection title="Second Interim Analysis (Median Follow-up Time: 36.6 Months)<sup>1</sup>">
                            
                            <SecondaryCallout
                                title='Statistically significant improvement in OS with KEYTRUDA + chemotherapy/KEYTRUDA vs placebo + chemotherapy/placebo<sup>a</sup>'
                                footnotes={featuredStatFootnotesOne}
                                alt=""
                            />

                            <KMCurve
                                title="Kaplan-Meier Estimates of OS in KEYNOTE&#8288;-&#8288;671"
                                hr="HR=0.72; 95% CI, 0.56—0.93; <i>P</i>=0.0103<sup>b-d</sup>"
                                footnotes={featuredStatFootnotesTwo}
                                definitions='CI = confidence interval; EFS = event-free survival; HR = hazard ratio; IO = immunotherapy; IV = intravenous; N+ = node positive; OS = overall survival; PD-L1 = programmed death ligand 1; T = tumor.'
                                image={() => <StaticImage src="../../assets/nsclc-km-kn-671-v2.png"
                                    placeholder="none"
                                    alt="Kaplan-Meier Estimates of Overall Survival (OS) in KEYNOTE-671" />
                                }
                            />
                            <FeaturedStat
                                statistic="28%"
                                title="Reduction in the risk of death with KEYTRUDA + chemotherapy/KEYTRUDA vs placebo + chemotherapy/placebo<div style='margin-top: 10px;'><b>(HR=0.72; 95% CI, 0.56-0.93; <i>P</i>=0.0103)<sup>e-g</sup></b></div>"
                            />
                            <ComparisonTable 
                                title="Overall Survival (OS)" 
                                footnotes={featuredStatFootnotesThree}
                                definitions="NR = not reached."
                                data={
                                [
                                    [
                                        {
                                            legendTitle: 'KEYTRUDA + chemo/KEYTRUDA (n=397)',
                                        },
                                        {
                                            legendTitle: 'Placebo + chemo/placebo (n=400)',
                                        },
                                    ],
                                    [
                                        {
                                            colspan: 2,
                                            centerText: true,
                                            hrCiP: 'HR=0.72 (95% CI, 0.56–0.93; <i>P</i>=0.0103)<sup>e-g</sup>'
                                        }
                                    ],
                                    [
                                        {
                                            label: 'Events Observed',
                                            dataPoint: '110',
                                            dataSubPoint: '(28%)'
                                        },
                                        {
                                            label: 'Events Observed',
                                            dataPoint: '144',
                                            dataSubPoint: '(36%)'
                                        },
                                    ],
                                    [
                                        {
                                            label: 'Median OS<sup>h</sup>',
                                            dataPoint: 'NR',
                                            dataSubPoint: '(95% CI, NR–NR)'
                                        },
                                        {
                                            label: 'Median OS<sup>h</sup>',
                                            dataPoint: '52.4 months',
                                            dataSubPoint: '(95% CI, 45.7–NR)'
                                        },
                                    ],
                                ]
                            } />
                        </PageSubSection>
                        <PageSubSection title="First Interim Analysis (Median Follow-up Time: 25.2 Months)<sup>2</sup>">
                            <SecondaryCallout
                                title='Statistically significant improvement in event-free survival (EFS) with KEYTRUDA + chemotherapy/KEYTRUDA vs placebo + chemotherapy/placebo'
                                alt=""
                            />

                            <FeaturedStat
                                statistic="42%"
                                title="Reduction in the risk of event (progression, recurrence, or death) with KEYTRUDA + chemotherapy/KEYTRUDA vs placebo + chemotherapy/placebo<div style='margin-top: 10px;'><b>(HR=0.58; 95% CI, 0.46&ndash;0.72; <i>P</i><0.0001)<sup>2,i-k</sup></b></div>"
                            />
                            <ComparisonTable 
                                title="Event-Free Survival" 
                                footnotes={pathologicalRrFootnotes}
                                data={
                                [
                                    [
                                        {
                                            legendTitle: 'KEYTRUDA + chemo/KEYTRUDA (n=397)',
                                        },
                                        {
                                            legendTitle: 'Placebo + chemo/placebo (n=400)',
                                        },
                                    ],
                                    [
                                        {
                                            colspan: 2,
                                            centerText: true,
                                            hrCiP: 'HR=0.58 (95% CI, 0.46–0.72; <i>P</i><0.0001)<sup>i-k</sup>'
                                        }
                                    ],
                                    [
                                        {
                                            label: 'Events Observed',
                                            dataPoint: '139',
                                            dataSubPoint: '(35%)'
                                        },
                                        {
                                            label: 'Events Observed',
                                            dataPoint: '205',
                                            dataSubPoint: '(51%)'
                                        },
                                    ],
                                    [
                                        {
                                            label: 'Median EFS<sup>l</sup>',
                                            dataPoint: 'NR',
                                            dataSubPoint: '(95% CI, 34.1–NR)'
                                        },
                                        {
                                            label: 'Median EFS<sup>l</sup>',
                                            dataPoint: '17.0 months',
                                            dataSubPoint: '(95% CI, 14.3–22.0)'
                                        },
                                    ],
                                ]
                            } />
                        </PageSubSection>
                        
                            <PageSubSection title="Pathological Response Rates">
                                <StudyTitle as='H3' dangerouslySetInnerHTML={createMarkup(htmlPRR)}></StudyTitle> 
                            </PageSubSection>
                        </PageSection>

                        <PageSection bgColor='cloud'>
                            <PageSubSection title={anchorLinkModelData[1].label}>
                                <NSCLC_NEOADJUVANT />
                            </PageSubSection>
                        </PageSection>

                        <PageSection
                            bgColor="cloud"
                            title={anchorLinkModelData[2].label}
                        >
                            <PageSubSection
                                title='Hypothetical Patients Based on Those Studied in KEYNOTE&#8288;-&#8288;671'
                            >
                                <PatientProfile {...patientProfileJess} />
                                <PatientProfile {...patientProfilePablo} />
                            

                                <SecondaryCallout 
                                    title="For your eligible patients with resectable (tumors ≥4 cm or node positive) NSCLC, start with KEYTRUDA in combination with platinum-containing chemotherapy in the neoadjuvant setting, then stay with KEYTRUDA as a single agent in the adjuvant setting after surgery."
                                    alt="Start KEYTRUDA® (pembrolizumab) With Chemotherapy in the Neoadjuvant Setting for High-Risk Early-Stage Triple-Negative Breast Cancer (TNBC) and Stay With KEYTRUDA® (pembrolizumab) as a Single Agent in the Adjuvant Setting"
                                />
                            </PageSubSection>
                        </PageSection>
                    </TemplateColumn>

                    {/* End Main Column */}
                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>
                
                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent}/>
                    </PageSection>
                    <PageSection bgColor="mist">
                        <ReferencesBlock references={pageReferences} />
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
